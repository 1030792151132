<template>
  <div class="feedback">
    <div class="feedback__form">
      <h2>Готовы ответить <br> на Ваш вопрос</h2>
      <v-form ref="form" v-model="valid">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="feedback.email"
            :rules="emailRules"
        >
          <template v-slot:label>
            Почта для связи
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="feedback.phone"
            :rules="phoneRules"
        >
          <template v-slot:label>
            Телефон
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="feedback.name"
            :rules="nameRules"
        >
          <template v-slot:label>
            Имя
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-textarea
            no-resize
            class="evi-textarea"
            color="#44D370"
            v-model="feedback.text"
            :rules="textRules"
        >
          <template v-slot:label>
            Текст
            <span class="important">*</span>
          </template>
        </v-textarea>
        <v-btn class="evi-button-green evi-button-green--fill" @click="send">
          Отправить
        </v-btn>
      </v-form>
    </div>
    <div class="feedback__img">
      <img class="feedback__hidden" :src="require('@/assets/img/feedback/feedback.svg')" alt=""/>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Feedback",
  data: () => ({
    feedback: {
      email: '',
      name: '',
      phone: '',
      text: '',
    },
    valid: true,
    textRules: [(v) => !!v || 'Введите текст'],
    nameRules: [(v) => !!v || 'Введите имя'],
    phoneRules: [(v) => !!v || 'Введите телефон'],
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
  },
  methods: {
    ...mapActions({
      sendFeedback: 'common/sendFeedback',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    send() {
      if (this.validate()) {
        this.sendFeedback(this.feedback).then(() => {
          this.feedback.email = '';
          this.feedback.name = '';
          this.feedback.phone = '';
          this.feedback.text = '';
          this.$refs.form.resetValidation();
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;

  @media (max-width: 1350px) {
    &__hidden {
      display: none;
    }
  }

  &__img {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      max-width: 741px;
    }
  }

  &__form {
    margin-right: 118px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 65px;
    min-width: 450px;
    width: 550px;

    h2 {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 27px;
      line-height: 115%;
    }

    .evi-button-green {
      width: 100%;
      height: 40px;
      margin-top: 60px;
    }
  }
}
</style>
